import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.scss";
import { Contact } from "./components/contact";
import { Footer } from "./components/footer";
import Header from "./components/header";
import MainDownload from "./components/mainDownload";
import Help from "./components/help";
import HowToSetup from "./components/howToSetup";
import Slider from "./components/slider";
import Tutorial from "./components/tutorial";
import i18n from "./i18n/i18n";
import StaticDocuments from "./components/staticDocuments";
import Error from "./components/error";
import firebase from "firebase/app";
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAddYUVFTKyDjuV3h3EDiLiJx_pj26Wfrg",
  authDomain: "pc-controller-7c542.firebaseapp.com",
  databaseURL: "https://pc-controller-7c542.firebaseio.com",
  projectId: "pc-controller-7c542",
  storageBucket: "pc-controller-7c542.appspot.com",
  messagingSenderId: "615990733084",
  appId: "1:615990733084:web:b27955bed4c5c67f0e72d9",
  measurementId: "G-F31H6S3DR5",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

function App() {
  const setLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const renderHeader = (type = "home") => {
    return type === "home" ? (
      <Header activeLanguage={i18n.language} setActiveLanguage={setLanguage} />
    ) : (
      <Header
        activeLanguage={i18n.language}
        setActiveLanguage={setLanguage}
        backgroundWithoutOpacity={true}
        useSimpleLinks={false}
      />
    );
  };

  return (
    <div className="App">
      <Router>
        <div className="content">
          <Switch>
            <Redirect exact from="/home" to="/" />
            <Redirect exact from="/pccontroller" to="/" />
            <Route exact path="/">
              {renderHeader()}
              <Slider />
              <Tutorial />
              <Help />
              <MainDownload />
              <Contact />
            </Route>
            <Route exact path="/help">
              {renderHeader("subpage")}
              <HowToSetup />
            </Route>
            <Route exact path="/privacy-policy">
              {renderHeader("subpage")}
              <StaticDocuments type="privacy_policy" />
            </Route>
            <Route exact path="/terms-and-conditions">
              {renderHeader("subpage")}
              <StaticDocuments type="terms_and_conditions" />
            </Route>
            <Route>
              {renderHeader("subpage")}
              <Error />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
