import React from "react";
import { Download } from "./download";
import ScrollableAnchor from "react-scrollable-anchor";
import Image from "./image";
import "./slider.scss";

const Slider = () => {
  return (
    <ScrollableAnchor id={"home"}>
      <div className="slider-container">
        <div className="slider-inner-container">
          <div className="slider-left">
            <h1 className="product-name wow fadeInDown">PC Controller</h1>
            <h2 className="product-short-description wow fadeInLeft">
              Control your computer with your phone.
            </h2>
            <Download />
          </div>
          <div
            className="slider-right wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay=".1s"
          >
            <Image source="header-app-intro" alt="Intro of applications" />
          </div>
        </div>
      </div>
    </ScrollableAnchor>
  );
};

export default Slider;
