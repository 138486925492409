import React, { useEffect } from "react";
import Help from "./help";
import TutorialItem from "./tutorialItem";
import {downloadForWindows} from '../common/config';
import firebase from "firebase/app";
import "firebase/analytics";
import "./tutorial.scss";
import "./howToSetup.scss";

const HowToSetup = () => {
  const analytics = firebase.analytics();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="tutorial-container how-to-setup" style={{ marginTop: "4rem" }}>
      <TutorialItem
        type="left"
        index={9}
        indexString=""
        img="first-screen-of-installer"
        descriptionNumber={1}
        link={downloadForWindows}
        linkIndex={9}
        analyticsOnLink={() => analytics.logEvent("download_for_windows")}
      />
      <TutorialItem
        type="right"
        index={10}
        indexString=""
        img="last-screen-of-installer"
        descriptionNumber={1}
      />
      <TutorialItem
        type="left"
        index={11}
        indexString=""
        img="find-the-application"
        descriptionNumber={1}
      />
      <TutorialItem
        type="right"
        index={12}
        indexString=""
        img="expected-qr-code-on-computer"
        descriptionNumber={1}
      />
      <TutorialItem
        type="left"
        index={13}
        indexString=""
        img="read-qr-code-from-app"
        descriptionNumber={1}
      />
      <TutorialItem
        type="right"
        index={14}
        indexString=""
        img="how-to-scan-with-phone"
        descriptionNumber={1}
      />
      <Help />
    </div>
  );
};

export default HowToSetup;
