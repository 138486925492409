import React from "react";
import * as config from "../common/config";
import "./footer.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Footer = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-container">
        <Link className="privacy-policy" to="/privacy-policy">
          {t("privacy_policy")}
        </Link>{" "}
        |{" "}
        <Link className="terms-and-conditions" to="/terms-and-conditions">
          {t("terms_and_conditions")}
        </Link>
        <p className="copy-right">
          &copy; Copyright {year} | PC Controller | {t("all_rights_reserved")}.
          |{" "}
          <a className="company" href={config.rnStudioLink}>
            RNStudio
          </a>
        </p>
      </div>
    </footer>
  );
};
