import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import PropTypes from "prop-types";
import Image from "./image";
import "./tutorialItem.scss";

const renderImg = (type, img, indexString, originalFormat) => {
  return (
    <div
      className={classnames(
        "image-container wow",
        type === "left" ? "fadeInLeft" : "fadeInRight"
      )}
      data-wow-duration=".7s"
      data-wow-delay=".5s"
    >
      <Image
        source={img}
        originalFormat={originalFormat}
        alt={`${indexString} step of tutorial`}
      />
    </div>
  );
};

const renderText = (
  t,
  descriptionNumber,
  index,
  type,
  linkIndex,
  link,
  analyticsOnLink
) => {
  const descriptionIndexes = [...Array(descriptionNumber).keys()];
  return (
    <div
      className={classnames(
        "text-container",
        type === "left" ? "on-left" : "on-right"
      )}
    >
      <h3>{t(`tutorial_${index}.title`)}</h3>
      {!!descriptionNumber &&
        descriptionNumber > 0 &&
        descriptionIndexes.map((item) => {
          return (
            <p
              key={item}
              className={classnames(
                "wow",
                type === "left" ? "fadeInLeft" : "fadeInRight"
              )}
            >
              {t(`tutorial_${index}.description_${item + 1}`)}.<br />
              {linkIndex === index && link && (
                <a href={link} onClick={() => analyticsOnLink()}>
                  Click here to download PCRemoteController_Setup_4_1.zip
                </a>
              )}
            </p>
          );
        })}
    </div>
  );
};

const TutorialItem = ({
  type = "left",
  index = 1,
  indexString = "First",
  img = "",
  originalFormat,
  descriptionNumber = 2,
  link = "",
  linkIndex = 0,
  analyticsOnLink = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div className="tutorial-inner-container">
      {type === "left" ? (
        <>
          {renderText(
            t,
            descriptionNumber,
            index,
            type,
            linkIndex,
            link,
            analyticsOnLink
          )}
          {renderImg(type, img, indexString, originalFormat)}
        </>
      ) : (
        <>
          {renderImg(type, img, indexString, originalFormat)}
          {renderText(
            t,
            descriptionNumber,
            index,
            type,
            linkIndex,
            link,
            analyticsOnLink
          )}
        </>
      )}
    </div>
  );
};

TutorialItem.propTypes = {
  type: PropTypes.oneOf(["left", "right"]),
  index: PropTypes.number.isRequired,
  indexString: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkIndex: PropTypes.number,
  originalFormat: PropTypes.string,
};

export default TutorialItem;
