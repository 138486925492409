import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    detection: { order: ["navigator"] },
    order: ["navigator"],
    resources: {
      en: {
        translations: {
          home: "Home",
          features: "Features",
          contact: "Contact",
          help: "Help",
          "tutorial_1.title": "Simple dashboard",
          "tutorial_1.list_1": "Easy pairing with QR codes",
          "tutorial_1.list_2": "Control your devices with the clear dashboard",
          "tutorial_1.list_3":
            "It can control any desktop or online media player",
          "tutorial_2.title": "Easy setup and handling",
          "tutorial_2.description_1":
            "After installing the application on your computer click it's icon on the taskbar to get the QR code",
          "tutorial_2.link_1":
            "Click here for the installation instructions, this guide will let you how you can setup the connection between your devices",
          "tutorial_2.description_2":
            "Install the PC Controller application from Play Store and scan the QR code from the computer's screen",
          "tutorial_3.title": "Easy controlling",
          "tutorial_3.description_1":
            "Besides controlling a media player you can also switch off or make sleep your computer, in case of laptop you can even adjust the brightness",
          "tutorial_4.title": "Controlling the cursor",
          "tutorial_4.description_1":
            "The screen can be used as a touchpad to control the cursor just like one would do with a laptop",
          "tutorial_5.title": "Controlling the keyboard",
          "tutorial_5.description_1": "Easy typing with the built in keyboard",
          "tutorial_6.title": "Special key combinations",
          "tutorial_6.description_1":
            "In this screen you can use special keyboard shortcuts like copying, pasting or cutting",
          "tutorial_7.title": "Multiple computers",
          "tutorial_7.description_1":
            "PC Controller application helps you to control multiple computers",
          "tutorial_8.title": "Pairing",
          "tutorial_8.description_1":
            "You only need to connect your devices once since the mobile app can find and connect to the computer if they are on the same network",
          "tutorial_9.title": "First step",
          "tutorial_9.description_1":
            "Please download and run the PC Remote Controller application installer on your computer. Follow the installer steps to setup the server program",
          "tutorial_10.title": "Second step",
          "tutorial_10.description_1":
            "At the end of the process, the installer will ask if you want to start the application, leave this option checked",
          "tutorial_11.title": "Third step",
          "tutorial_11.description_1":
            "You will find an icon on your desktop called PC Remote Controller.exe, double-click it, or locate the same icon on the taskbar (if the application is running) and click on it. If you can't find it, look for it as PC Remote Controller between the installed programs",
          "tutorial_12.title": "Fourth step",
          "tutorial_12.description_1":
            "After starting the program, the QR code will be displayed, which you can scan using the PC Controller application installed on your Android device",
          "tutorial_13.title": "Fifth step",
          "tutorial_13.description_1":
            "After installing the PC Controller on your Android device, you will see a button on the home page of application, which will start the scanning process, your device will ask for permission to use the camera",
          "tutorial_14.title": "Sixth step",
          "tutorial_14.description_1":
            "Hold your mobile device towards the monitor and scan the QR code from your computer. The connection will then be established, otherwise please read the information below on the cause of the connection error",
          get_start_free: "Download now for free",
          "contact.title": "Contact",
          "contact.email": "Email",
          "contact.email.placeholder": "Email address",
          "contact.email.invalid": "Wrong email address",
          "contact.subject": "Subject",
          "contact.subject.placeholder": "Subject",
          "contact.message": "Message",
          "contact.message.placeholder": "Message",
          "contact.field.required": "Field required",
          "contact.send": "Send message",
          "contact.send.success": "Message was sent successfully",
          "contact.send.unsuccess":
            "An error occured during sending the message",
          all_rights_reserved: "All rights reserved",
          privacy_policy: "Privacy policy",
          terms_and_conditions: "Terms & conditions",
          not_found_short: "Not found",
          not_found_long:
            "It looks like the page you're looking for does not exist",
          not_found_redirectLinkText: "Go to homepage",
          "install.step_1": "STEP 1",
          "install.step_1_title": "Download and install the server on your PC",
          "install.step_2": "STEP 2",
          "install.step_2_title": "Install the app on your phone",
          help_question_1: "Why cannot I connect my phone to my PC?",
          help_answer_1_1:
            "If you've paired your device with your PC and connection doesn't working, please add the",
          help_answer_1_2:
            "to the whitelist to allow it use network. If you don't know how you can do this, please read the following link",
          help_answer_2_1:
            "Please make sure, your mobile device and PC are connected to the same Wi-Fi network.",
          help_answer_3_1:
            "Please make sure, that PC Remote Controller application is running on your PC.",
          help_link_1: "How to add exception to windows firewall",
          help_link_1_language_code: "en-us",
          help_question_2: "My antivirus software detects virus.",
          help_answer_4_1: "The PC Remote Controller computer server requires certain computer privileges in order to communicate with the mobile app. Some anti-virus software may classify it as a virus, but in fact the software itself does no virus behavior.",
        },
      },
      hu: {
        translations: {
          home: "Kezdőlap",
          features: "Előnyök",
          contact: "Kapcsolat",
          help: "Segítség",
          "tutorial_1.title": "Letisztult kezelőfelület",
          "tutorial_1.list_1":
            "Könnyedén tudja párosítani eszközeit QR kód segítségével",
          "tutorial_1.list_2":
            "Eszközei irányítását egy letisztult vezérlő segíti",
          "tutorial_1.list_3":
            "Bármilyen videólejátszó program, akár online film irányítására is alkalmas",
          "tutorial_2.title": "Könnyű telepítés és kezelés",
          "tutorial_2.description_1":
            "Miután telepítette az alkalmazást a számítógépére, csak keresse meg a tálcán a PC Controller ikonját, majd kattintson rá a QR kód megnyitásához",
          "tutorial_2.link_1":
            "Telepítési útmutató lépésről lépésre, kattintson ide",
          "tutorial_2.description_2":
            "Telepítse a Play Storeból elérhető PC Controllert, majd olvassa be a számítógépén megjelenő QR kódot",
          "tutorial_3.title": "Letisztult kezdőlap",
          "tutorial_3.description_1":
            "Médialejátszó műveleteken kívül a számítógépét kikapcsolhatja, alvó módba teheti és laptop esetében a fényerejét is tudja módosítani",
          "tutorial_4.title": "Egér irányítás",
          "tutorial_4.description_1":
            "Telefonja kijelzőjéből touchpadot készítettünk, ezáltal úgy tudja irányítani az egeret, mintha csak egy laptop lenne a kezében",
          "tutorial_5.title": "Billentyűzet irányítás",
          "tutorial_5.description_1":
            "Egyszerűen tud szöveget átvinni a számítógépére",
          "tutorial_6.title": "Speciális kombinációk",
          "tutorial_6.description_1":
            "A speciális menüpont segít Önnek billentyűkombinációk létrehozására, amivel többek között másolhat, beilleszthet, vagy akár kivághat is",
          "tutorial_7.title": "Több számítógép",
          "tutorial_7.description_1":
            "A PC Controller applikáció segít Önnek egyszerre több számítógépet is irányítani",
          "tutorial_8.title": "Párosítás",
          "tutorial_8.description_1":
            "Eszközeit elég egyszer párosítani, a telefonja képes újra megtalálni a számítógépét és csatlakozni, ha ugyanazon a WiFi hálózaton vannak",
          "tutorial_9.title": "Első lépés",
          "tutorial_9.description_1":
            "Töltse le majd futtassa a PCRemoteController_Setup_4_1.zip nevű telepítőt. Kövesse a telepítő lépéseit a program telepítéséhez a számítógépén",
          "tutorial_10.title": "Második lépés",
          "tutorial_10.description_1":
            "A telepítő a folyamat végén megkérdezi, hogy elindítsa-e az alkalmazást, hagyja kipipálva ezt a lehetőséget",
          "tutorial_11.title": "Harmadik lépés",
          "tutorial_11.description_1":
            "Az asztalon találni fog egy ikont PC Remote Controller.exe néven, kattintson rá kétszer, vagy keresse meg ugyanezt az ikont a tálcán (ha fut az alkalmazás) és kattintson rá. Ha nem találja, akkor keressen rá PC Remote Controller néven a telepített programok között",
          "tutorial_12.title": "Negyedik lépés",
          "tutorial_12.description_1":
            "Miután elindította a programot, megjelenik a QR code amit az androidos készülékén telepített PC Controller alkalmazás segítségével tud beolvasni",
          "tutorial_13.title": "Ötödik lépés",
          "tutorial_13.description_1":
            "A PC Controller telepítése után az androidos készülékén a kezdőlapon egy gombot fog látni, amelynek megnyomásával elindul a beolvasási folyamat, készüléke engedélyt fog kérni a kamera használatához",
          "tutorial_14.title": "Hatodik lépés",
          "tutorial_14.description_1":
            "Mobil készülékét tartsa a monitor felé és olvassa be a QR kódot a számítógépéről. Ezután a kapcsolat létre fog jönni, ellenkező esetben kérem olvassa el a lenti tájékoztatást a hiba okáról",
          get_start_free: "Töltse le most ingyenesen",
          "contact.title": "Kapcsolat",
          "contact.email": "Email",
          "contact.email.placeholder": "Email cím",
          "contact.email.invalid": "Hibás email cím",
          "contact.subject": "Tárgy",
          "contact.subject.placeholder": "Üzenet tárgya",
          "contact.message": "Üzenet",
          "contact.message.placeholder": "Üzenet",
          "contact.field.required": "Kötelező mező",
          "contact.send": "Üzenet küldése",
          "contact.send.success": "Sikeres küldés",
          "contact.send.unsuccess": "Sikertelen küldés",
          all_rights_reserved: "Minden jog fenntartva",
          privacy_policy: "Adatvédelmi irányelvek",
          terms_and_conditions: "Felhasználási feltételek",
          not_found_short: "A keresett oldal nem található",
          not_found_long:
            "Sajnáljuk, de az Ön által keresett oldal nem található",
          not_found_redirectLinkText: "Ugrás a kezdőlapra",
          "install.step_1": "Első lépés",
          "install.step_1_title":
            "Töltse le és telepítse a számítógépére a szervert",
          "install.step_2": "Második lépés",
          "install.step_2_title": "Telepítse az alkalmazást a telefonjára",
          help_question_1:
            "Miért nem csatlakozik a készülékem a számítógépemhez?",
          help_answer_1_1:
            "Ha párosította készülékét számítógépével és a kapcsolat nem jön létre, kérem adja hozzá a",
          help_answer_1_2:
            "alkalmazást kivételként a tűzfalhoz. Ha nem tudja hogyan kell, kérem kövesse a következő linket",
          help_answer_2_1:
            "Kérem ellenőrizze, hogy a számítógépe és a készüléke ugyanahhoz a Wi-Fi hálózathoz csatlakozik.",
          help_answer_3_1:
            "Kérem ellenőrizze, hogy a PC Remote Controller alkalmazás fut a számítógépén.",
          help_link_1: "Kivétel hozzáadása a windows tűzfalhoz",
          help_link_1_language_code: "hu-hu",
          help_question_2: "A vírusírtó szoftver riasztást adott az alkalmazásra.",
          help_answer_4_1: "A PC Remote Controller alkalmazás úgynevezett websocket-en keresztül kommunikál az android készülékekkel, egyes vírusírtók ezt vírusként azonosíthatják."
        },
      },
    },
    fallbackLng: "en",
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
