import React, { useState, useEffect } from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import { useTranslation } from "react-i18next";
import axios from "axios";
import * as config from "../common/config";
import "./contact.scss";

export const Contact = () => {
  const { t } = useTranslation();

  const testEmail = (email) => {
    // eslint-disable-next-line
    var regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email.toLowerCase());
  };

  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [emailError, showEmailError] = useState(false);
  const [emailInvalidError, showEmailInvalidError] = useState(false);
  const [subjectError, showSubjectError] = useState(false);
  const [messageError, showMessageError] = useState(false);
  const [success, setSuccess] = useState(null);

  const validateEmail = (email) => {
    if (!email || email.length === 0) {
      showEmailError(true);
      return false;
    } else {
      showEmailError(false);
      if (!testEmail(email)) {
        showEmailInvalidError(true);
        return false;
      }
      showEmailInvalidError(false);
    }
    return true;
  };

  const validateSubject = (subject) => {
    if (!subject || subject.length === 0) {
      showSubjectError(true);
      return false;
    }
    showSubjectError(false);
    return true;
  };

  const validateMessage = (message) => {
    if (!message || message.length === 0) {
      showMessageError(true);
      return false;
    }
    showMessageError(false);
    return true;
  };

  useEffect(() => {
    let timeout;
    if (success !== null) {
      timeout = setTimeout(() => {
        setEmail("");
        setSubject("");
        setMessage("");

        showEmailError(false);
        showEmailInvalidError(false);
        showSubjectError(false);
        showMessageError(false);
        setSuccess(null);
      }, 2500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [success]);

  const handleSubmit = () => {
    let emailValid = validateEmail(email);
    let subjectValid = validateSubject(subject);
    let messageValid = validateMessage(message);
    if (emailValid && subjectValid && messageValid) {
      // send email
      axios({
        method: "post",
        url: `${config.CONTACT_API_PATH}`,
        headers: { "content-type": "application/json" },
        data: { email: email, subject: subject, message: message },
      })
        .then((result) => {
          if (result.data.status === 200) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
        })
        .catch((error) => {
          setSuccess(false);
        });
    }
  };

  return (
    <ScrollableAnchor id={"contact"}>
      <div className="contact-container">
        <div className="contact-inner-container">
          <div className="contact-title">
            <h3>{t("contact.title")}</h3>
          </div>
          <div className="contact-inputs">
            <div className="contact-input-simple">
              <h4>{t("contact.email")}</h4>
              <div>
                <input
                  type="text"
                  className="text-input"
                  placeholder={t("contact.email.placeholder")}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value);
                  }}
                  value={email}
                />
                {(emailError || emailInvalidError) && (
                  <div className="error-icon" />
                )}
              </div>
              {emailError && <div className="error-text">{t("contact.field.required")}!</div>}
              {emailInvalidError && (
                <div className="error-text">{t("contact.email.invalid")}!</div>
              )}
            </div>
            <div className="contact-input-simple">
              <h4>{t("contact.subject")}</h4>
              <div>
                <input
                  type="text"
                  className="text-input"
                  placeholder={t("contact.subject.placeholder")}
                  onChange={(e) => {
                    setSubject(e.target.value);
                    validateSubject(e.target.value);
                  }}
                  value={subject}
                />
                {subjectError && <div className="error-icon" />}
              </div>
              {subjectError && <div className="error-text">{t("contact.field.required")}!</div>}
            </div>
            <div className="contact-input-simple">
              <h4>{t("contact.message")}</h4>
              <div>
                <textarea
                  className="textarea-input"
                  placeholder={t("contact.message.placeholder")}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    validateMessage(e.target.value);
                  }}
                  value={message}
                />
                {messageError && <div className="error-icon" />}
              </div>
              {messageError && <div className="error-text">{t("contact.field.required")}!</div>}
            </div>
          </div>
          <div className="contact-submit">
            <button className="submit-btn" onClick={() => handleSubmit()}>
              {success === null && <div>{t("contact.send")}</div>}
              {success === true && <div>{t("contact.send.success")}</div>}
              {success === false && <div>{t("contact.send.unsuccess")}</div>}
              {success && (
                <div className="success-checkmark">
                  <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                  </div>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </ScrollableAnchor>
  );
};
