import React from "react";
import { Download } from "./download";
import "./mainDownload.scss";
import { useTranslation } from "react-i18next";

const MainDownload = () => {
  const { t } = useTranslation();
  return (
    <div className="main-download-container">
      <div className="main-download-inner-container">
        <h3
          className="wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay=".1s"
        >
          {t("get_start_free")} <br />
        </h3>
        <div
          className="wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay=".1s"
        >
          <Download />
        </div>
      </div>
    </div>
  );
};

export default MainDownload;
