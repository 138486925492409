import React from "react";
import PropTypes from "prop-types";

const Image = ({ source, originalFormat, width, height, alt, style }) => (
  <picture>
    <source srcset={`img/${source}.webp`} type="image/webp" />
    <source
      srcset={`img/${source}.${originalFormat}`}
      type={originalFormat === "png" ? "image/png" : "image/jpeg"}
    />
    <img
      src={`img/${source}.${originalFormat}`}
      width={width}
      style={style}
      height={height}
      alt={alt}
    />
  </picture>
);

Image.propTypes = {
  source: PropTypes.string.isRequired,
  originalFormat: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  style: PropTypes.object,
};

Image.defaultProps = {
  originalFormat: "png",
};

export default Image;
