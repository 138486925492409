export const downloadForWindows = "https://pccontroller.rnstudio.hu/windows/PCRemoteController_Setup_4_1.zip";
export const downloadForAndroid = "https://play.google.com/store/apps/details?id=com.pccontroller";
export const downloadForAndroidBeta = "https://play.google.com/apps/testing/com.pccontroller";
export const FULL_PATH = "https://pccontroller.rnstudio.hu";
export const rnStudioLink = "https://rnstudio.hu";
export const rnStudioEmail = "info@rnstudio.hu";
export const pcRemoteControllerWindowsVersion = "4.1.0";
export const pcControllerVersion = "3.3.0";
export const pcControllerBetaVersion = "3.3.0";
export const CONTACT_API_PATH = "https://pccontroller.rnstudio.hu/contact-api/index.php";
