import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./header.scss";
import { useTranslation } from "react-i18next";
import Image from "./image";

const Header = (props) => {
  const { t } = useTranslation();
  const {
    activeLanguage,
    setActiveLanguage,
    backgroundWithoutOpacity,
    useSimpleLinks,
  } = props;
  const [bgOpacity, setBgOpacity] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [language, setLanguage] = useState(activeLanguage);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (!global.window) return;
    const scrollTop = window.document.documentElement.scrollTop;
    setBgOpacity((scrollTop - 95) / 100);
  };

  const toogleMenu = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  const changeLanguage = () => {
    if (language === "hu") {
      setLanguage("en");
      setActiveLanguage("en");
    } else {
      setLanguage("hu");
      setActiveLanguage("hu");
    }
  };

  return (
    <header>
      <div
        className="header-background"
        style={{
          opacity: showMenu || backgroundWithoutOpacity ? 1 : bgOpacity,
        }}
      />
      <div className="header-container">
        <div className="mobile-left-nav-icon">
          <div
            id="nav-icon"
            onClick={toogleMenu}
            className={showMenu ? "open" : ""}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="left-logo">
          <Link to="/">
            <div className="logo">
              <Image
                source={"pc-remote-controller-logo"}
                alt="Logo of Remote Controller"
                width={20}
              />
            </div>
            <div className="logo-text">PC Controller</div>
          </Link>
        </div>
        <div className="center-nav">
          <nav>
            <ul id="navigation">
              {useSimpleLinks ? (
                <>
                  <li>
                    <a href="/#home">{t("home")}</a>
                  </li>
                  <li>
                    <a href="/#features">{t("features")}</a>
                  </li>
                  <li>
                    <a href="/help">{t("help")}</a>
                  </li>
                  <li>
                    <a href="/#contact">{t("contact")}</a>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link
                      to={{
                        pathname: "/",
                        hash: "#home",
                      }}
                    >
                      {t("home")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/",
                        hash: "#features",
                      }}
                    >
                      {t("features")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/help",
                        hash: "",
                      }}
                    >
                      {t("help")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/",
                        hash: "#contact",
                      }}
                    >
                      {t("contact")}
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
        <div className="right-languages" onClick={changeLanguage}>
          <Image
            source={language === "hu" ? "en" : "hu"}
            alt="Switch language"
            width={50}
            height={30}
          />
          {language === "hu" ? <p>English</p> : <p>Magyar</p>}
        </div>
      </div>
      <div className={classnames("mobile-menu", showMenu && "collapse")}>
        <ul style={{ visibility: showMenu ? "visible" : "hidden" }}>
          {useSimpleLinks ? (
            <>
              <li>
                <a href="#home" onClick={toogleMenu}>
                  {t("home")}
                </a>
              </li>
              <li>
                <a href="#features" onClick={toogleMenu}>
                  {t("features")}
                </a>
              </li>
              <li>
                <a href="/help" onClick={toogleMenu}>
                  {t("help")}
                </a>
              </li>
              <li>
                <a href="#contact" onClick={toogleMenu}>
                  {t("contact")}
                </a>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link
                  to={{
                    pathname: "/",
                    hash: "#home",
                  }}
                  onClick={toogleMenu}
                >
                  {t("home")}
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: "/",
                    hash: "#features",
                  }}
                  onClick={toogleMenu}
                >
                  {t("features")}
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: "/help",
                    hash: "",
                  }}
                  onClick={toogleMenu}
                >
                  {t("help")}
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: "/",
                    hash: "#contact",
                  }}
                  onClick={toogleMenu}
                >
                  {t("contact")}
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </header>
  );
};

Header.propTypes = {
  activeLanguage: PropTypes.oneOf(["en", "hu"]).isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
  backgroundWithoutOpacity: PropTypes.bool,
  useSimpleLinks: PropTypes.bool,
};

Header.defaultProps = {
  activeLanguage: "en",
  setActiveLanguage: () => {},
  backgroundWithoutOpacity: false,
  useSimpleLinks: true,
};

export default Header;
