import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./tutorial.scss";
import TutorialItem from "./tutorialItem";
import Image from "./image";

const Tutorial = () => {
  const { t } = useTranslation();
  return (
    <ScrollableAnchor id="features">
      <div className="tutorial-container">
        <div className="tutorial-inner-container">
          <div className="text-container on-left">
            <h3>{t("tutorial_1.title")}</h3>
            <ul>
              <li
                className="wow fadeInUp"
                data-wow-duration=".7s"
                data-wow-delay=".5s"
              >
                {t("tutorial_1.list_1")}.
              </li>
              <li
                className="wow fadeInUp"
                data-wow-duration=".8s"
                data-wow-delay=".6s"
              >
                {t("tutorial_1.list_2")}.
              </li>
              <li
                className="wow fadeInUp"
                data-wow-duration=".9s"
                data-wow-delay=".7s"
              >
                {t("tutorial_1.list_3")}.
              </li>
            </ul>
          </div>
          <div
            className="image-container wow fadeInRight"
            data-wow-duration=".8s"
            data-wow-delay=".7s"
          >
            <Image
              source="tutorial-of-windows-app"
              style={{ marginTop: "5rem" }}
              alt="First step of introducing"
            />
          </div>
        </div>
        <div className="tutorial-inner-container">
          <div
            className="image-container wow fadeInLeft"
            data-wow-duration=".8s"
            data-wow-delay=".7s"
          >
            <Image
              source="read-qr-code-from-app"
              style={{ marginTop: "5rem" }}
              alt="Second step of introducing"
            />
          </div>
          <div className="text-container on-right">
            <h3>{t("tutorial_2.title")}</h3>
            <p className="wow fadeInRight">{t("tutorial_2.description_1")}.</p>
            <Link className="wow fadeInRight tutorial-link" to="/help">
              {t("tutorial_2.link_1")}!
            </Link>
            <p className="wow fadeInRight">{t("tutorial_2.description_2")}.</p>
          </div>
        </div>
        <TutorialItem
          type="left"
          index={3}
          indexString="Third"
          img="pc-controller-home-screen"
          descriptionNumber={1}
        />
        <TutorialItem
          type="right"
          index={4}
          indexString="Fourth"
          img="mouse-control-screen"
          descriptionNumber={1}
        />
        <TutorialItem
          type="left"
          index={5}
          indexString="Fifth"
          img="keyboard-control-screen"
          descriptionNumber={1}
        />
        <TutorialItem
          type="right"
          index={6}
          indexString="Sixth"
          img="special-keys-control-screen"
          descriptionNumber={1}
        />
        <TutorialItem
          type="left"
          index={7}
          indexString="Seventh"
          img="how-to-add-more-devices"
          descriptionNumber={1}
        />
        <TutorialItem
          type="right"
          index={8}
          indexString="Eighth"
          img="device-can-detect-your-computer"
          descriptionNumber={1}
        />
      </div>
    </ScrollableAnchor>
  );
};

export default Tutorial;
