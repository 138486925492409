import React from "react";
import "./help.scss";
import { useTranslation } from "react-i18next";

const Help = () => {
  const { t } = useTranslation();
  return (
    <div className="help-container">
      <div className="help-inner-container">
        <div className="help-text">{t("help")}</div>
        <div className="question">{t("help_question_1")}</div>
        <div className="answer">
          <b>1.</b> {t("help_answer_1_1")}{" "}
          <span className="exe-name">PC Remote Controller.exe</span>{" "}
          {t("help_answer_1_2")}:{" "}
          <a
            href={`https://support.microsoft.com/${t(
              "help_link_1_language_code"
            )}/help/4028485/windows-10-add-an-exclusion-to-windows-security`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("help_link_1")}.
          </a>
        </div>
        <div className="answer">
          <b>2.</b> {t("help_answer_2_1")}
        </div>
        <div className="answer">
          <b>3.</b> {t("help_answer_3_1")}
        </div>
        <div className="question">{t("help_question_2")}</div>
        <div className="answer">
          {t("help_answer_4_1")}{" "}
        </div>      
      </div>
    </div>
  );
};

export default Help;
