import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./error.scss";

const Error = (props) => {
  const {
    statusCode,
    shortDescription,
    longDescription,
    redirectLink,
    redirectLinkText,
  } = props;
  const { t } = useTranslation();
  return (
    <div className="not-found-container">
      <div className="not-found-inner-container">
        <div className="left-column">
          <div className="not-found-statuscode">{statusCode}</div>
          <div className="not-found">{t(shortDescription)}</div>
        </div>
        <div className="right-column">
          <div className="description">{t(longDescription)}.</div>
          <div className="go-to-homepage">
            <Link to={redirectLink}>
              <div className="homepage-button">{t(redirectLinkText)}</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

Error.propTypes = {
  statusCode: PropTypes.number.isRequired,
  shortDescription: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  redirectLink: PropTypes.string.isRequired,
  redirectLinkText: PropTypes.string.isRequired,
};

Error.defaultProps = {
  statusCode: 404,
  shortDescription: "not_found_short",
  longDescription: "not_found_long",
  redirectLink: "/",
  redirectLinkText: "not_found_redirectLinkText",
};

export default Error;
