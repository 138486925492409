import React from "react";
import * as config from "../common/config";
import "./download.scss";
import firebase from "firebase/app";
import { useTranslation } from "react-i18next";
import Image from "./image";
import "firebase/analytics";

export const Download = () => {
  const analytics = firebase.analytics();
  const { t } = useTranslation();
  return (
    <div className="download-container">
      <div className="download-inner-container">
        <h2>
          <b>{t("install.step_1")}</b> - {t("install.step_1_title")}
        </h2>
        <div className="download-for-windows">
          <a
            href={config.downloadForWindows}
            title="Download PC Remote Controller for windows"
            data-version={config.pcRemoteControllerWindowsVersion}
            onClick={() => analytics.logEvent("download_for_windows")}
          >
            <Image
              source="download-for-windows"
              width={167}
              height={50}
              alt="Download for windows"
            />
          </a>
        </div>
        <h2>
          <b>{t("install.step_2")}</b> - {t("install.step_2_title")}
        </h2>
        <div className="download-for-android">
          <a
            href={config.downloadForAndroid}
            title="Download PC Controller for android"
            data-version={config.pcControllerVersion}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => analytics.logEvent("download_for_android")}
          >
            <Image
              source="download-playstore"
              originalFormat="svg"
              alt="Download for android"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
